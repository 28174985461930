import React, { lazy } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContentSlug, closePlayer, openPlayer } from 'redux/video/video.slice';
import { getSingleFavourite } from 'redux/favourite/favourite.slice';
import WatchNowCard from 'components/video/WatchNowCard';
import { openModal } from 'redux/modal/modal.slice';
import { addItemToWishlist } from 'redux/wishlist/wishlist.slice';
import { Box } from 'components/styled/box';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { LoaderCircle } from 'components/peek/styles';
import AdsComponent from 'components/AdsComponent';
import { mixPanelAnalytics } from 'utils/MixpanelAnalytics';
import NotFoundComponent from 'components/NotFoundComponent';
import { isNumeric } from 'utils/numberCheck';
import jwt from 'jwt-decode';
import { signInSuccess } from 'redux/user/user.actions';
import { setUserSub } from 'redux/userSubInfo/userSub.slice';
import { checkTvodSubscription } from 'redux/video/video.utils';
import { callbackTypeThree, callbackTypeTwo } from 'utils/gp-callbacks';
import axios from 'axios';
import { API_URL } from 'utils/api-url.config';
import { gpSubscriptionStatus } from 'redux/subscription/subscription.utils';
const VideoRecomandation = lazy(() => import('components/video/VideoRecomandation'));
const Recommendation = lazy(() => import('components/Recommendation'));

export default function TvodExclusiveSlug() {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const { banner, currentTrack, showPlayer, loading } = useSelector((state) => state.video);
  const { favourite } = useSelector((state) => state.favourite);
  const { userProfiles } = useSelector((state) => state.userSub);
  const { video: items } = useSelector((state) => state.wishlist);
  const title = window?.platform_info?.title;
  const asthaUser = JSON.parse(localStorage.getItem(`astha_user_${title}`));
  const gpUser = JSON.parse(localStorage.getItem(`gp_user_${title}`));
  const { currentUser } = useSelector((state) => state.user);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const history = useHistory();
  const [hasTvodSub, setTvodSub] = React.useState(false);
  const [isGpSubscribed, setGpSubscribe] = React.useState('');
  const accessToken = JSON.parse(localStorage.getItem(`access_token_r_${title}`));
  const [watchTime, setWatchTime] = React.useState(null);

  // Following useEffect has to be removed cause this route only for gp mirrorlink
  // React.useEffect(() => {
  //   const callGpSubscriptionCheck = async () => {
  //     const response = await gpSubscriptionStatus(accessToken);
  //     if (response?.data?.data.status) {
  //       setGpSubscribe('OK');
  //     } else if (response?.data?.data.status === false) {
  //       setGpSubscribe('NOT_OK');
  //     }
  //   };

  //   if (gpUser && (currentTrack?.premium || currentTrack?.tvod)) {
  //     callGpSubscriptionCheck();
  //   }
  // }, [gpUser]);

  React.useEffect(() => {
    dispatch(getContentSlug(slug));
  }, [dispatch, slug]);

  React.useEffect(() => {
    if (currentTrack?.id) {
      dispatch(getSingleFavourite(currentTrack?.id));
    }
  }, [dispatch, currentTrack?.id]);

  React.useEffect(() => {
    const callTvodAPI = async () => {
      if (currentTrack?.tvod) {
        const res = await checkTvodSubscription(currentTrack?.id);
        if (res?.data.data.status) {
          setTvodSub(true);
        } else {
          setTvodSub(false);
        }
      }
    };
    callTvodAPI();
  }, [currentTrack?.id]);

  React.useEffect(() => {
    mixPanelAnalytics.trackWebScreen('Video details by slug');
  }, []);

  //Triggering gp event callback
  React.useEffect(() => {
    if (currentTrack && gpUser) {
      callbackTypeThree({ name: 'Video', params: ['content', banner?.title] });
      if (currentTrack?.slug && currentTrack?.tvod && currentTrack?.tvodexclusive) {
        if (!hasTvodSub) {
          // Show gp payment Deeplink to user
          console.log('Show Gp Payment deeplink to user');
        }
      }
    }
  }, [currentTrack, gpUser]);

  function onPlayVideo() {
    dispatch(openPlayer());
  }
  function onClose() {
    dispatch(closePlayer());
  }
  function handleAddToList(data) {
    dispatch(addItemToWishlist(data));
  }
  function handleCreateList(contentId) {
    dispatch(openModal({ type: 'CREATE_WISHLIST_MODAL', data: { contentId } }));
  }

  if (loading) return <LoaderCircle />;

  return (
    <>
      <Wrapper>
        <Helmet>
          <meta name="description" content={currentTrack && currentTrack.description} />
          <meta property="og:title" content={currentTrack && currentTrack.title} />
          <meta property="og:type" content="video.movie" />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_IMAGE_URL}900x472/${banner?.horizontalThumbnails[0]?.path}`}
          />
          <title>{`${currentTrack?.title} - ${title}`}</title>
        </Helmet>
        <Box>
          <WatchNowCard
            imageUrl={`${process.env.REACT_APP_IMAGE_URL}700xauto/${banner?.horizontalThumbnails[0]?.path}`}
            title={banner?.title}
            description={banner?.description}
            releaseDate={banner?.releaseDate}
            genres={banner?.genres}
            duration={banner?.durationInSeconds}
            onClickPlay={onPlayVideo}
            onAddToListClick={handleAddToList}
            onCreateNewListClick={handleCreateList}
            wishlist={items}
            directors={banner?.directors}
            casts={banner?.casts}
            producers={banner?.producers}
            id={currentTrack?.id}
            currentTrack={currentTrack}
            onClose={onClose}
            share="video"
            favourite={favourite}
            subtitle={banner?.subtitles}
            hasTvodSub={hasTvodSub}
            isGpSubscribed={isGpSubscribed}
            watchTime={watchTime}
            setWatchTime={setWatchTime}
          />
        </Box>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  @media (min-width: 1280px) {
    padding: 0;
    max-width: 1256px;
    margin: 0 auto;
  }

  @media (min-width: 650px) {
    padding: 0 50px;
  }
`;

import React, { lazy, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSeries,
  getSeriesSlug,
  openPlayer,
  closePlayer,
  changeTrack
} from 'redux/video/video.slice';
import WatchNowCard from 'components/video/WatchNowCard';
import { openModal } from 'redux/modal/modal.slice';
import { addItemToWishlist, displayWishlists } from 'redux/wishlist/wishlist.slice';
import { Box } from 'components/styled/box';
import styled from 'styled-components';
import { Series } from 'components/video/Series';
import { Helmet } from 'react-helmet';
import { LoaderCircle } from 'components/peek/styles';
import { mixPanelAnalytics } from 'utils/MixpanelAnalytics';
import { isNumeric } from 'utils/numberCheck';
import NotFoundComponent from 'components/NotFoundComponent';
import AdsComponent from 'components/AdsComponent';
const Recommendation = lazy(() => import('components/Recommendation'));
import jwt from 'jwt-decode';
import { signInSuccess } from 'redux/user/user.actions';
import { setUserSub } from 'redux/userSubInfo/userSub.slice';
import { callbackTypeThree, callbackTypeTwo } from 'utils/gp-callbacks';
import { checkTvodSubscription } from 'redux/video/video.utils';
import axios from 'axios';
import { API_URL } from 'utils/api-url.config';
import { gpSubscriptionStatus } from 'redux/subscription/subscription.utils';

export default function SeriesDetailSlug() {
  const search = useLocation().search;
  // const seasonparam = new URLSearchParams(search).get('season_index') - 1;
  // const episodeparam = new URLSearchParams(search).get('episode_index') - 1;
  const { userProfiles } = useSelector((state) => state.userSub);
  const { currentUser } = useSelector((state) => state.user);
  let { slug } = useParams();
  const title = window?.platform_info?.title;
  const asthaUser = JSON.parse(localStorage.getItem(`astha_user_${title}`));
  const history = useHistory();
  const gpUser = JSON.parse(localStorage.getItem(`gp_user_${title}`));
  const [hasTvodSub, setTvodSub] = React.useState(false);
  const [isGpSubscribed, setGpSubscribe] = React.useState('');
  const accessToken = JSON.parse(localStorage.getItem(`access_token_r_${title}`));
  const [watchTime, setWatchTime] = React.useState(null);

  // if (!isNumeric(id)) {
  //   return <NotFoundComponent />;
  // }
  const dispatch = useDispatch();
  const { banner, tracks, currentTrack, showPlayer, loading } = useSelector((state) => state.video);
  const { series: items } = useSelector((state) => state.wishlist);

  //keeping current track into variable so that it can be chaged
  const [episodeIndex, setEpisodeIndex] = useState(0);
  const [seriesIndex, setSeriesIndex] = useState(0);

  React.useEffect(() => {
    const callGpSubscriptionCheck = async () => {
      const response = await gpSubscriptionStatus(accessToken);
      if (response?.data?.data.status) {
        setGpSubscribe('OK');
      } else if (response?.data?.data.status === false) {
        setGpSubscribe('NOT_OK');
      }
    };

    if (gpUser && (currentTrack?.premium || currentTrack?.tvod)) {
      callGpSubscriptionCheck();
    }
  }, [gpUser]);

  // React.useEffect(() => {
  //   setEpisodeIndex(Number(episodeparam));
  //   setSeriesIndex(Number(seasonparam));
  // }, [seasonparam, episodeparam]);

  React.useEffect(() => {
    dispatch(getSeriesSlug(slug));
  }, [dispatch, slug]);
  React.useEffect(() => {
    dispatch(displayWishlists('series'));
  }, [dispatch]);

  React.useEffect(() => {
    const callTvodAPI = async () => {
      if (currentTrack?.tvod && currentUser) {
        const res = await checkTvodSubscription(currentTrack?.id);
        console.log('Console logging tvod check api data', res);
        if (res?.data.data.status) {
          setTvodSub(true);
        } else {
          setTvodSub(false);
        }
      }
    };
    callTvodAPI();
  }, [currentTrack, currentTrack?.id, currentUser]);

  function onPlayVideo() {
    dispatch(openPlayer());
  }
  React.useEffect(() => {
    dispatch(
      changeTrack({
        episodeIndex: episodeIndex < 0 ? 0 : episodeIndex,
        seriesIndex: seriesIndex < 0 ? 0 : seriesIndex
      })
    );
    // localStorage.setItem('IntroStart', )
  }, [dispatch, episodeIndex, seriesIndex, tracks]);

  React.useEffect(() => {
    mixPanelAnalytics.trackWebScreen('Series details by slug');
  }, []);

  //Triggering gp event callback
  React.useEffect(() => {
    if (currentTrack && gpUser) {
      callbackTypeThree({ name: 'Series', params: ['content', banner?.title] });
      if (currentTrack?.premium || currentTrack?.tvod) {
        if (isGpSubscribed === 'OK') {
          callbackTypeTwo(window.location.href, false);
          console.log('PackPopup if track premium & subscribed', window.location.href, false);
        } else if (isGpSubscribed === 'NOT_OK') {
          console.log('PackPopup if track premium & not subscribed', window.location.href, true);
          callbackTypeTwo(window.location.href, true);
        }
      } else {
        console.log('PackPopup if track free', window.location.href, false);
        callbackTypeTwo(window.location.href, false);
      }
    }
  }, [currentTrack, gpUser, isGpSubscribed]);

  function onClose() {
    dispatch(closePlayer());
  }
  function handleAddToList(data) {
    dispatch(addItemToWishlist(data));
  }
  function handleCreateList(contentId) {
    dispatch(openModal({ type: 'CREATE_WISHLIST_MODAL', data: { contentId } }));
  }
  if (loading) return <LoaderCircle />;

  return (
    <>
      <Wrapper>
        <Helmet>
          <meta name="description" content={currentTrack && currentTrack.description} />
          <meta property="og:title" content={currentTrack && currentTrack.title} />
          <meta property="og:type" content="video.series" />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_IMAGE_URL}900x472/${banner?.thumbnails?.[0]?.path}`}
          />
          <title>{`${currentTrack?.title} - ${title}`}</title>
        </Helmet>
        <Box px={[0, 50]}>
          <WatchNowCard
            imageUrl={`${process.env.REACT_APP_IMAGE_URL}700xauto/${banner?.thumbnails?.[0]?.path}`}
            title={banner?.title}
            description={banner?.description}
            releaseDate={banner?.releaseDate}
            genres={banner?.genres}
            directors={banner?.directors}
            casts={banner?.casts}
            producers={banner?.producers}
            duration={banner?.durationInSeconds}
            onClickPlay={onPlayVideo}
            onAddToListClick={handleAddToList}
            onCreateNewListClick={handleCreateList}
            wishlist={items}
            showPlayer={showPlayer}
            currentTrack={currentTrack}
            onClose={onClose}
            share="series"
            id={currentTrack?.id}
            episodeIndex={episodeIndex}
            seriesIndex={seriesIndex}
            setEpisodeIndex={setEpisodeIndex}
            setSeriesIndex={setSeriesIndex}
            series={tracks[seriesIndex]}
            seriesLength={tracks.length}
            hasTvodSub={hasTvodSub}
            from="slug"
            watchTime={watchTime}
            setWatchTime={setWatchTime}
            isGpSubscribed={isGpSubscribed}
          />
        </Box>
        <Box>
          <div className="d-none-mid">
            {/* {window.platform_info.api_key === 'iscreen' && !userProfiles?.subscribe && (
              <AdsComponent />
            )} */}
          </div>
          <Series
            tracks={tracks}
            onClickPlay={onPlayVideo}
            currentTrack={currentTrack}
            seriesIndex={seriesIndex}
            episodeIndex={episodeIndex}
            setIndex={setEpisodeIndex}
            setSeriesIndex={setSeriesIndex}
            id={currentTrack?.id}
            from="slug"
          />
        </Box>
      </Wrapper>
      {/* {window.platform_info.api_key === 'iscreen' && !asthaUser && !userProfiles?.subscribe && <AdsComponent />} */}
      {banner?.type && (
        <Recommendation
          contentId={currentTrack?.id}
          type={banner?.type}
          orientationType={'horizontal'}
        />
      )}
    </>
  );
}
const Wrapper = styled.div`
  display: grid;
  gap: 1rem 0;
  .d-none-mid {
    display: none;
  }
  @media (min-width: 1000px) {
    grid-template-columns: 2fr 1fr;
    gap: 2rem 0;
    .d-none-mid {
      display: block;
    }
  }
`;

import jwtDecode from 'jwt-decode';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function GpRedirection() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const decodedGPTOken = token && jwtDecode(token);

  useLayoutEffect(() => {
    if (decodedGPTOken?.partner && decodedGPTOken?.partner == 'GP_MYGP') {
      window.location.href = 'https://mygp.grameenphone.com/mygp/pack/sms/4431';
    }
  }, [decodedGPTOken]);

  return null;
}

export default GpRedirection;
